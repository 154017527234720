.search-filters{
    .filters-dropdown {
        &.open{
            .toggle-icons{
                .icon{
                    &.closed{
                        display: none;
                    }
                    &.opened{
                        display: block;
                    }
                }
            }
        }
        .toggle-icons {
            display: inline-block;
            position: absolute;
            right: 5px;
            top: 10px;
            margin-top: -5px;
            margin-right: 5px;
            margin-left: 23px;
            .icon{
                vertical-align: middle;
                font-size: 50px;
                float: right;
                right: 0;
                top: 10px;
                &.closed{
                    display: block;
                }
                &.opened{
                    display: none;
                }
            }
        }
    }
}