.breadcrumbs {
    background: #fff;
    ul {		
        li {
            margin: 3px;
            float: left;
            font-size: 12px;
            &>span, &>a {
                padding: 10px 0;
                display: block;
            }
            a {
                text-decoration: underline;
                font-weight: 700;
                color: #000;   
            }
        }
    }
} 