.effect-fihs{
    transition: opacity .5s ease-in-out;
    -moz-transition: opacity .5s ease-in-out;
    -webkit-transition: opacity .5s ease-in-out;

    &.effect-fihs-hidden{
        opacity: 0!important;
    }
}

.effect-fiv{
    transition: opacity 1s ease-in-out;
    -moz-transition: opacity 1s ease-in-out;
    -webkit-transition: opacity 1s ease-in-out;

    &.effect-fiv-hidden{
        opacity: 0!important;
    }
}

.effect-fios{
    animation-name: effect-general-show;
    animation-duration: 500ms;
    animation-iteration-count: 1;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;

    &.effect-fios-hidden{
        animation-name: effect-general-hide;
        z-index: -1!important;
    }
}

@include mixin-prefix-keyframes("effect-general-show") {
    0% {
      opacity:0;
    }
}

@include mixin-prefix-keyframes("effect-general-hide") {
    0% {
      display:block;
    }
    100% {
      opacity:0;
      display:none;
    }
}

.effect-general{

    transition: opacity 1s ease-in-out;
    -moz-transition: opacity 1s ease-in-out;
    -webkit-transition: opacity 1s ease-in-out;

    &.effect-general-hidden{
        opacity: 0!important;
    }

}
