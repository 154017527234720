@import 'general';

.modal.modal-vo-default,
.modal {
  .modal-dialog{
    margin-top: 175px;
  }

  .modal-close {
    right: -55px;
    top: -100px;
  }

}
#reseller-modal .modal-dialog {
  margin-top: 0;
}