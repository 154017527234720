.spinner {
  position: relative;
  height: 30px;
  width: 30px;
  margin: 0 auto;
  border: 6px solid rgba(0, 0, 0, 0.2);
  border-top: 6px solid rgba(0, 0, 0, 0.8);
  border-radius: 100%;
  -webkit-animation: rotation 0.6s infinite linear;
  animation: rotation 0.6s infinite linear;
  display: inline-block;
  vertical-align: middle;
  &.black{
    border: 6px solid #fff;
    border-top: 6px solid rgba(0, 0, 0, 0.8);
  }
}

@-webkit-keyframes rotation {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
  }
}
@keyframes rotation {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
