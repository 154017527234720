.modal-body {
    padding: 0;
}

.modal-content {
    border: none;
}

.reseller-dropdown{
    z-index: 1051;
}

#reseller-authentication-form{

    label.error{
        display: none!important;
    }

    .invalid-error-message{
        display: none;
        color: #FE0002;
    }

    &.error{
        .invalid-error-message{
            display: block;
        }
    }

    input.error{
        border-color: #FE0002;
        @include placeholder {
            color: #FE0002;
        };
    }

   .error.select-div {
       border-color: #FE0002;
       .select2-selection__rendered{
           color: #FE0002;
       }
   }
}

.reseller-dropdown .select2-dropdown--below {
    margin-top: 3px;
}
.reseller-dropdown.select2-container {
    z-index: 1051;
}