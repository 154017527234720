.widget-highlights {
    padding: 30px 0;
    overflow: hidden;
    h2{
        font-size: 25px;
    }

    .highlights-container {
        margin: 15px auto;
        &.slick-slider {
            overflow: hidden;
        }

        .slick-list {
            margin: 0 -15px;
        }
        .arrow{
            @include arrow;
        }

        .vo-carousel-item{
            &.slick-slide {
                margin: 0 15px;
            }
            figure{ 
                &>img{
                    margin-bottom: 15px;
                }
                figcaption h3, .description{
                    @extend .text-regular;
                    width: 100%; 
                }
            }

        }
    }
}