.widget-social {
    span{
        font-size: 50px;
        margin-left: -5px;
        margin-right: -5px;
    }
    a:focus{
        text-decoration: none;
        color:inherit;
    }
}

