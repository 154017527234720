//General
@import '../../partials/general';
//Partials
@import '../../partials/tablet/general';
@import '../../partials/tablet/header';
@import '../../partials/tablet/footer';
//widgets
@import '../../widgets/highlights/tablet';
//Store
@import '../../store/showcase/tablet';

@mixin background-cover($height, $background){
    width: 100%;
    height: $height;
    position:relative;
    background: url($background) center center;
    background-size: cover;
}

main{
    background-color: #fff;
}

.rotator>img{
    display: inherit;
    width: 100%;
}
.slick-dots {
    position: absolute;
    text-align: center;
    width: 100%;
    bottom: 10px;
    &>li{
        @include carousel-selector(#fff);
        &.slick-active{
            @include carousel-selector(#fe0000);
        }
        button {
            display: none;
        }
    }
}

#rotator-cover-large {
    max-height: 600px;
    overflow: hidden;
    width: 100%;
    position: relative;

    .slider{
        background-color: #262626;
        position: static;
        &.with-news {
            min-height: 100px;
        }
        &.vo-carousel-load-fix{
            height: 600px;
            opacity: 1;
        }
    }

    .frame{
        position: static;
    }

    .rotator{
        max-height: 600px;
        overflow: hidden;
        width: 100%;
        position: relative;
    }

    & .arrow{
        position: absolute;
        bottom: 320px;
        color: #fff;
        font-size: 50px;
        &.left{
            left:0;
        }
        &.right{
            right:0;
        }
    }

    .slick-dots {
        position: absolute;
        bottom: 120px  ;
        text-align: center;
        width: 100%;
        &>li{
            @include carousel-selector(#fff);
            &.slick-active{
                @include carousel-selector(#fe0000);
            }
        }
    }
}

.news {
    @extend .text-regular;
    background-color: rgba(255,255,255,0.65);
    overflow: hidden;
    left:0;
    right:0;
    height:100px;
    bottom:0;
    position:absolute;
    text-align: left;
    color: #000;
    margin: 0 auto;
    z-index: 1;
    width: 738px;

    .owl-controls{
        padding-top: 15px;
    }
    .slick-slide.slick-active{
        &:nth-child(n+2):before {
            content: '';
            border-left: solid black 1px;
            position: absolute;
            min-height: 70px;
            left: 0px;
        }
        &.slick-current:nth-child(n+2):before {
            content: '';
            border-left: 0;
        }
    }


    .arrow{
        @include arrow;

        &.left{
            margin-left: 5px;
        }

        &.right{
            margin-right: 5px;
        }
    }

    .frame {
        position: relative;

        a, a:hover, a:active{
            text-decoration: none;
            color: inherit;
        }

        .frame.vo-carousel-load-fix{
            overflow: hidden;
            height: 130px;
        }

        .news-item {
            display: inline-block;
            width: 369px;
            white-space: normal;
            position: relative;
            vertical-align: top;
            padding: 15px 10px;

            .news-content{
                @extend .text-regular;

                .date{
                    font-weight: 600;
                }
            }

            a {
                display: -webkit-box;
                -webkit-line-clamp: 4;
                -webkit-box-orient: vertical;
                overflow: hidden;
                line-height: 15px;
            }
        }
    }
}

.slider-t2{
    padding: 15px 0;
    position:relative;
    .carousel-slider {
        position: relative;
        .slick-slide {
            position: relative;
        }
        & .description {
            background-color: #000;
            padding: 54px 0;
            width: 210px;
            height: 100%;
            opacity: 0.85;
            h2 {
                font-size: 28px;
                letter-spacing: 0.1em;
                line-height: 30px;
                text-align: center;
                color: #fff;
                font-weight: 700;
                margin: 0 0 15px 0;
            }
            h3 {
                font-size: 18px;
                line-height: 21px;
                letter-spacing: 0.1em;
                text-align: center;
                text-transform: uppercase;
                color: #fff;
                font-weight: 300;
                margin: 0;
                padding: 0 20px;
            }
        }
        & .arrow {
            width: 50px;
            height: 90px;
            border-radius: 3px;
            background-color: rgba(0,0,0,0.25);
            color: #fff;
            position: absolute;
            bottom: 105px;
            font-size: 50px;
            margin: auto 15px;
            &.left{
                left:0;
            }
            &.right{
                right:0;
            }
            span{
                position: absolute;
                top: 20px;
            }
        }
    }

    .carousel-selector {
        position: absolute;
        bottom: 20px;
        text-align: center;
        left:0;
        right:0;
        &>.option{
            @include carousel-selector(#fff);
        }
        &>.option.selected{
            @include carousel-selector(#fe0000);
        }
    }

    .carousel-slider{
        .vo-carousel-load-fix{
            max-height: 231px;
        }
    }
}

.espaco-lingua-portuguesa{
    background: url('../../../images/lp_bg.png') center center;
    background-size: cover;
    width:100%;
    padding: 30px 0px;
    min-height: 200px;

    .container-elp{
        position: relative;
        padding: 25px 0;
        &>div{
            width: 50%;
            display: inline-block;
            float: left;
        }
    }

    hr {
        width: 1px;
        left: 50%;
        background-color: #fff;
        height: 160px;
        position: absolute;
        top: -10px;
    }


    h4{
        text-align: left;
        font-weight: bold;
        font-size: 25px;
        width: 100%;
        color: #fff;
        margin: 0 auto 15px auto;
    }

    .left{
        a{
            color: #fe0000;
        }
    }

    .right{
        a{
            color: #fda100;
        }
    }

    a{
        margin: 10px 0;
        display: block;
        text-decoration: none;
    }

    form.single-field{
        position:relative;
        width: 100%;
        input[type=text] {
            display: inline-block;
            width: 100%;
            font-size: 14px;
            height: 50px;
            padding: 17px 60px 15px 30px;
            border: none;
            color: #000;
            border-radius: 0;
            @include placeholder {
                text-align:center;
                color: #a4a4a4;
                font-size: 11px;
                text-transform: uppercase;
                padding-top: 2px;
            }
        }
        button{
            outline: none;
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            width: 30px;
            background-color: #fda100;
            border: none;
            span{
                color:#fff;
                font-size: 50px;
                position: absolute;
                right: -10px;
                top: 0;
            }
        }
    }

    a.single-button{
        outline: none;
        width: 100%;
        font-size: 11px;
        line-height: 14px;
        color: #fff;
        padding: 18px 0;
        text-align: center;
        text-transform: uppercase;
        background-color: #fe0000;
        border: none;
        position: relative;
        margin: 0;
        span{
            color:#fff;
            font-size: 50px;
            position: absolute;
            top:1px;
            right: -10px;
        }
    }
}

#descricao{
    .description-container{
        text-align: center;
        margin-top: 50px;
    }
        .button {
            @include button-type-a(#000);
            margin: 0 auto;
            padding: 11px 0px;
            text-decoration: none;
            width: 160px;
        }
}

#destaques-1{
    a, a:hover, a:active{
        text-decoration: none;
        color: inherit;
    }

    .highlights-container.vo-carousel-load-fix{
        overflow: hidden;
        max-height: 261px;
    }
    .highlights-container{
        img {
            max-height: 125px;
        }
        &.slick-slider .slick-list {
            margin: 0 -30px 0 0;
            .slick-slide {
                margin-right: 30px;
                a {
                    display: block;
                    width: 255px;
                }
                img {
                    min-height: 0;
                    min-width: 0;
                }
            }
        }
        .arrow{
            &.left{
                margin-left: 5px;
            }

            &.right{
                margin-right: 15px;
            }
        }
    }


}

.destaques-2 {
    background-color: #828282;
    padding: 25px 0;
    overflow: hidden;

    .items-container.vo-carousel-load-fix{
        overflow: hidden;
        max-height: 212px;
    }

    .sep{
        height: 1px;
        width:100%;
        background-color: #fff;
        margin: 20px auto;
    }

    h2{
        font-size: 25px;
        text-align: center;
        text-transform: uppercase;
        color: #fff;
    }

    h3{
        font-size: 25px;
        line-height: 28px;
        color: #fff;
        margin: 0 0 15px 0;
    }

    //.p-relative{
    //    padding-bottom: 65px;
    //}

    .item{
        position: relative;
        height: 260px;
    }

    .items-container {
        li{
            white-space: normal;
            vertical-align: top;
        }

        .img-container img {
            width: 67px;
            height: 67px;
            margin: 0 auto;
        }

        .description{
            @extend .text-regular;
            margin: 20px auto 10px auto;
        }

        p{
            @extend .text-regular;
            color: #fff;
        }
        .button {
            @include button-type-a(#fff);
            position: absolute;
            bottom: 0;
            left: 50%;
            margin-left: -80px;
            padding: 11px 0px;
            text-decoration: none;
            width: 160px;
        }
    }

    .arrow{
        @include arrow;
    }
}

.destaques-3{

    h2{
        font-size: 25px;
        margin-bottom: 15px;
        text-transform: uppercase;
    }

    li{
        padding: 30px 0;
        width: 100%;
        &.separator {
            border-bottom: solid #000 1px;
        }
    }

    section {

        h3{
            font-size: 25px;
            line-height: 28px;
        }

        .author-date, .description {
            @extend .text-regular;
        }

        .info-container {
            a{
                text-decoration: none;
                .button {
                    @include button-type-a(#000);
                    display: inline-block;
                    width: 160px;
                    height: 40px;
                }
            }
        }

        .img-container {
            text-align: right;
            width: 195px;
            display: inline-block;
            vertical-align: top;
            .circle-img {
                width: 100%;
                border-radius: 50%;
            }
        }

        .info-container{
            display: inline-block;
            width: 538px;
            padding: 0 0 0 30px;

            .description{
                margin-bottom: 20px;
            }
        }
    }
}

.destaques-4 {
    background-color: #d9d9d9;
    padding: 38px 0;
    font-size: 14px;
    line-height: 18px;

    .items-container.vo-carousel-load-fix{
        overflow: hidden;
        max-height: 67px;
    }

    .arrow{
        @include arrow;
    }

    .img-container{
        float: left;
        margin-right: 10px;

        img{
            min-width: 60px;
            width: auto;
            height: auto;
            vertical-align: top;
        }
    }

    h2 {
        font-size: 14px;
        line-height: 18px;
    }

    .description{
        padding-top: 4px;
    }

    a, a:hover, a:active{
        text-decoration: none;
        color: inherit;
    }
}

.destaques-5 {
    background-color: #828282;
    padding: 25px 0;
    margin: 15px 0 30px 0;    
    overflow: hidden;

    .highlights-container.vo-carousel-load-fix{
        overflow: hidden;
        max-height: 212px;
    }

    .vo-carousel-item{
        position: relative;
        height: 260px;
    }

    .highlights-container{
        &.slick-slider .slick-list {
            .slick-slide {
                width: 246px;
            }
        }
        .arrow{
            &.left{
                margin-left: 5px;
            }

            &.right{
                margin-right: 15px;
            }
        }
    }    
    
    .highlights-container {
        li{
            white-space: normal;
            vertical-align: top;
        }

        .img-container img {
            width: 67px;
            height: 67px;
            margin: 0 auto;
        }

        .description{
            @extend .text-regular;
            margin: 20px auto 10px auto;
        }

        p{
            @extend .text-regular;
            color: #fff;
        }
        .button {
            @include button-type-a(#fff);
            position: absolute;
            bottom: 0;
            left: 50%;
            margin-left: -80px;
            padding: 11px 0px;
            text-decoration: none;
            width: 160px;
        }
    }
    
    h3{
        font-size: 25px;
        line-height: 28px;
        color: #fff;
        margin: 0 0 15px 0;
    }

}

// Client asked to use large background starting on 1024px
@media all and (min-width: 1024px) {
    #rotator-cover-large {
        .background-cover{
            &.large {
                display: block!important;
            }
            &.medium {
                display: none!important;
            }
        }
    }
}