.actions-dropdown{
    font-size: 15px;
    margin-right: 15px;
    &.open{
        .dropdown-toggle{
            box-shadow: none;
            -webkit-box-shadow: none;
            .toggle-icons{
                .icon{
                    &.closed{
                        display: none;
                    }
                    &.opened{
                        display: block;
                    }
                }
            }
        }
    }
    .actions-toggle{
        width: 100%;
        border: 1px solid #000;
        height: 40px;
        line-height: 39px;
        background-color: #fff;
        text-align: left;
        padding: 0px 0px 0px 15px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        .actions-title{
            font-weight: bold;
        }
        .active{
            font-weight: 600;
            color: #fe0000;
        }
        .total-count{
            color: #fe0000;
        }
        .toggle-icons{
            display: inline-block;
            position: absolute;
            right: 5px;
            top: 9px;
            margin-top: -13px;
            margin-left: -5px;
            margin-right: -5px;
            .icon{
                vertical-align: middle;
                font-size: 50px;
                float: right;
                right: 0;
                top: 5px;
                &.closed{
                    display: block;
                }
                &.opened{
                    display: none;
                }
            }
        }
    }
    .actions-items{
        background-color: #fff;
        width: 100%;
        padding: 0px;
        border-radius: 0px;
        text-align: left;
        font-weight: 600;
        margin: 0px;
        border: 1px solid #000;
        border-top: none;
        z-index: 2;
        .item{
            &.active{
                color: #9d9d9d;
            }
            &.option{
                &:hover{
                    background-color: #d9d9d9;
                }
            }
            &.last{
                margin-bottom: 0px;
            }
            a, p{
                display: block;
                padding: 10px 15px 10px 15px;
            }
            &.raw{
                padding: 10px 15px 10px 15px;
                cursor: pointer;
                a, p{
                    display: block;
                    padding: 0px;
                }
            }
            .total-count{
                font-weight: normal;
            }
        }
    }
}

@media only screen and (max-width : 767px) {
    .actions-dropdown {
        width: 100%;
        .dropdown-backdrop{ // Fix for touch devices
            position: static;
        }
    }
}

@media only screen and (min-width : 768px) and (max-width : 1199px) {
    .actions-dropdown {
        margin-right: 5px;
        .actions-toggle{
            width: 300px;
        }
        .dropdown-backdrop{ // Fix for touch devices
            position: static;
        }
    }
}

@media only screen and (min-width : 1200px) {
    .actions-dropdown {
        .actions-toggle{
            width: 300px;
        }
    }
}