.modal{

    .modal-close {
        font-family: "PE_icons";
        font-size: 50px;
        position: absolute;
        color: #fff;
        text-shadow: none;
        opacity: 1;
        font-weight: 400;
        &:hover, &:focus{
            color: #fff;
            opacity: 1;
        }
    }

    .title{
        font-size: 25px;
        text-transform: uppercase;
        margin-bottom: 20px;
    }

    &.rect-modal{
        .modal-content{
            border-radius: 0px;
            padding: 30px 20px;
            .subtitle{
                margin-bottom: 15px;
            }
            .description{
                margin-bottom: 15px;
            }
            .hiddenRecaptcha {
                visibility: hidden;
            }
            .modal-form{
                .form-fields-group-title{
                    margin-bottom: 5px;
                    font-size: 20px;
                }
                .form-field{
                    margin-bottom: 10px;
                    .form-field-help{
                        font-size: 12px;
                    }
                }
                input, textarea{
                    width: 100%;
                    border: 1px solid #000;
                    padding: 8px;
                    height: 40px;
                    font-size: 11px;
                }
                textarea{
                    resize: none;
                    height: 160px;
                }
                .clear-btn{
                    @include button-type-a(#000);
                    margin-right: 15px;
                }
                .submit-btn{
                    @include button-type-b(#fe0000);
                }
                a{
                    &.submit-btn{
                        display: inline-block;
                        padding-top: 12px;
                    }
                }
                .full-with{
                    margin-top: 30px;
                    button, a{
                        width: 100%;
                        margin: 0px;
                        &.submit-btn{
                            margin-top: 10px;
                            height: 40px;
                        }
                    }
                }
                .status-message{
                    margin-top: 10px;
                }
                .reCaptcha {
                    border: none !important;
                    height: 80px!important;
                    margin-top: 40px !important;            
                    margin-bottom: 40px !important;
                    div {
                        border: none !important;
                        height: 0px!important;
                        margin-bottom: 0px !important;
                        width: 0px!important;
                        input {
                            margin: 0px!important;
                            line-height: 0px!important;
                        }
                        &.g-recaptcha {
                            height: 80px!important;
                            border: none;
                            display: block;
                            float: none;
                            margin-bottom: 5px!important;
                            padding: 0;
                            div {
                                border: none;
                            }
                        }
                    }
                }
            }
        }
    }
    &.modal-vo-default{
        .item-remove-icon {
            font-family: 'PE_icons';
            font-size: 25px;
            font-weight: bold;
            line-height: 25px;
            margin-left: 10px;
            color: #fff;
            position: absolute;
            top: 80px;
            right: 15%;
        }
        .modal-content{
            padding: 140px 30px 130px 30px;
        }
        .modal-dialog {
            margin-top: 180px;
        }
        .title{
            text-align:center;
        }

        .subtitle{
            font-weight: 700;
            font-size: 14px;
            line-height: 18px;
            text-align: center;
        }

        .content{
            &.margin-subtitle{
                margin-top: 90px;
            }
            &.margin-title{
                margin-top: 70px;
            }
            font-size: 14px;
            line-height: 18px;
            text-align: center;
        }
    }
}

.modal-backdrop.fade.in {
    z-index: 999;
}