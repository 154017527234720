@import '../../partials/general';
@import '../../partials/tablet/general';
@import '../../partials/tablet/header';
@import '../../partials/tablet/footer';
@import '../../widgets/breadcrumbs/general';
@import '../../widgets/social/general';
@import '../../partials/general/actions';
@import '../../partials/general/actions_filter';
@import '../common/shopping_buttons';
@import 'general';

.showcase-container {

    .showcase {
        .left-details{
            .title{
              font-size: 18px;
              line-height: 22px;
            }
        }
        .products-showcase{
            padding-top: 35px;
            padding-bottom: 35px;
            .slick-arrow {
                &.left{
                    left: 20px;
                }
                &.right{
                    right: 25px;
                }
            }
            &.showcase-1-1, &.showcase-2x{
                .product {
                    .discount-percentage {
                        right: -10px;
                    }
                }
            }
            &.showcase-4x{
                .left-details{
                    .title{
                        font-size: 25px;
                        line-height: 29px;
                    }
                }
                .products-list {
                  padding: 0px;
                  margin: 0px;
                }
            }
            &.showcase-6x{
                padding-bottom: 35px;
            }
            &.showcase-image-1, &.showcase-image-2, &.showcase-image-3, &.showcase-video-1, &.showcase-video-2, &.showcase-video-3{
                padding-bottom: 35px;
                padding-top: 35px;
                .products-list {
                    .slick-arrow {
                        top: 335px;
                    }
                    .product {
                        .product-details {
                            .extra-information {
                                margin-top: 0px;
                            }
                        }
                    }
                }
            }
            &.showcase-video-1{
                padding-bottom: 50px;
            }
            &.showcase-video-3{
                .products-list {
                    .slick-arrow {
                        top: 100px;
                    }
                }
            }
            &.showcase-video-2, &.showcase-video-1{
                .media-container{
                    height: 200px;
                }
            }
            &.fixed-showcase{
                .showcase-title{
                    padding-left: 15px;
                }
                &.showcase-2x{
                    .showcase-title{
                        padding-left: 0px;
                    }
                }
                .fix-product-container{
                    float: left;
                    .fixed-image-container{
                        width: 175px;
                        float: left;
                        padding-left: 15px;
                    }
                    .fixed-details-container{
                        width: 355px;
                        float: left;
                        margin-left: 30px;
                        margin-right: 15px;
                    }
                }
            }
        }
    }
}

.featured-products-slider {
    .featured-products-slider-arrow{
        &.a-left{
            margin-left: -365px;
        }
        &.a-right{
            margin-left: 365px;
        }
    }
    .featured-products-wrapper {
        .featured-products {
             height: 275px;
             .featured-product{
                max-height: 300px;
                .picture {
                    margin-left: 15px;
                    max-width: 280px;
                }
                .details {
                    padding-right: 0px;
                    .details-container {
                        vertical-align: top;
                        max-width: 465px;
                        padding-top: 20px; 
                        .title{
                            font-size: 36px;
                            line-height: 40px;
                            padding-bottom: 30px;
                        }
                        .subtitle{
                            font-size: 25px;
                            line-height: 28px;
                            padding-bottom: 20px;
                        }
                    }
                }
             }
        }
    }
    .slick-slider{
        .slick-dots{
          bottom: 10px;
        }
    }
    .slick-slider{
      .arrow {
        &.left {
          left: calc(50% - 364px);
        }
        &.right {
          right: calc(50% - 364px);
        }
      }
    }
}

.showcase-container {
    .showcase {
        .products-showcase {
            .ver-mais-botao {
                /*margin-top: 40px;*/
                margin-top: 20px;
            }
        }
        &.news {
            .products-showcase{
                .slick-arrow {
                    &.left{
                        left: 5px;
                    }
                    &.right{
                        right: 5px;
                    }
                }
            }
        }
    }
    .featured-products-slider {
        padding-top: 35px;
        padding-bottom: 35px;
        .featured-products-wrapper {
            .featured-products {
                height: 225px;
                .featured-product {
                    .picture {
                        max-width: 255px;
                    }
                    .details {
                        .details-container {
                            max-width: 330px;
                        }
                    }
                }
            }
        }
    }
}
