@import '_general.scss';

.widget_revendedor {
	z-index: 999;
	position: absolute;
	width: 360px;
	top: 20px;
	left: -30px;
	padding-top: 50px;
	.revendedor {
	    width: 750px;
    	margin: 0 auto;
    	padding: 0 10px;
		h2 {
			font-size: 25px;
			line-height: 29px;
			text-transform: uppercase;
		}
		p {
			font-size: 14px;
			line-height: 18px;
			margin: 20px 0 15px 0;
		}
		.login{
			background: #fff;
			width: 360px;
			padding: 30px;
			float: left;
			form{
				.select-div {
					width: 300px;
					height: 40px;
					border: 1px solid #000;
					font-size: 16px;
					margin-top: 5px;
					@include placeholder {
						font-size:11px;
						color: #a4a4a4;
						text-transform: uppercase;
						padding: 5px 0;
					}
				}
				input {
					width: 300px;
					height: 40px;
					border: 1px solid #000;
					font-size: 16px;
					padding: 8px 15px;
					margin: 5px 0 10px;
					@include placeholder{
						font-size:11px;
						color: #a4a4a4;
						text-transform: uppercase;
						padding: 5px 0;
					}
				}
				a {
					font-size: 14px;
					color: #fe0000;
					margin: 10px 0 0 0;
					width: 100%;
					display: inline-block;
				}
	            button {
	                border: 1px solid black;
	                background: transparent;
	                border-radius: 0;
	                width: 160px;
	                height: 40px;
	                font-size: 11px;text-transform: uppercase;
					margin-top: 20px;
	            }
			}
		}
		.info {
			color: #fff;
			opacity: .75;
			width: 250px;
			float: left;
			margin-left: 30px;
			h2 {
				margin-bottom: 20px;
			}
			p {
				margin: 0;
				&:last-child {
					margin-bottom: 20px;
				}
			}
		}
	}
}
