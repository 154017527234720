.arrow-footer-menu {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    text-align: right;
    text-decoration: none;
    span{
        color: #9d9d9d;
        font-size: 50px;
        text-decoration: none;
    }
}

footer{

    .layer-1 {
        background-color: #262626;
        min-height:50px;
        color: #fff;
        text-align: center;

        .title-container {
            position: relative;
        }

        .overlay {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 1;
        }

        h4{
            padding: 17px 0;
            font-size: 14px;
            color: #9d9d9d;
        }

        .vo-container {
            position: relative;
        }

        ul {
            margin: 14px 0 15px 0;
            display: none;

            li {
                display: inline-block;
                a, span{
                    color:#9d9d9d;
                    font-size:12px;
                    line-height: 28px;
                    text-decoration: none;
                }
            }
        }

        &.open {
            h4 {
                color: #ffffff;
            }
            ul {
                display: block
            }
        }

        .arrow{
            @extend .arrow-footer-menu;
        }

        &.open .arrow span{
            color: #fe0000;
        }
    }

    .layer-2 {
        background-color: #000;
        color: #9d9d9d;
        padding: 15px 0 25px 0;
        font-size: 12px;
        line-height: 18px;

        .menu{
            &.col-5-tablet{
                list-style: none;
                .col {
                    box-sizing: content-box;
                    display: inline-block;
                    float: left;
                    vertical-align: top;
                    width: 20%;

                    &.col-size-3{
                        width: 147px;
                        &.col-1,
                        &.col-8
                        {
                            width: 162px;
                        }
                        padding: 0 15px 0 15px;
                    }
                    
                    &.col-1{
                        padding-left: 0;
                    }

                    &.col-8{
                        padding-right: 0;
                    }
                }
            }

            .title {
                color: #fff;
                margin-bottom: 5px;
                margin-top: 20px;
                font-weight: 700;
                &.first{
                    margin-top: 0;
                }
                a {
                    color: #fff;
                }
            }

            .option .arrow, .option .icon-U {
                display: none;
            }

            .vo-container{
                width: 100%;
                padding: 0;
                & > .clearfix:after {
                    margin-bottom: 35px;
                }
            }

            a {
                text-decoration: none;
                color: #9d9d9d;
                &:hover{
                    text-decoration: underline;
                }
                &.active{
                    color: #fe0000;
                }
            }

            .item{
                margin: 10px 0;
                &.first{
                    margin-top: 28px;
                }
            }

            .clearer{
                &:before {
                    height: 15px;
                    width: 100%;
                }
            }
        }

        p{
            font-size: 12px;
            text-align: left;
        }

        .link-list {
            display: none;
        }

        h6 {
            div{
                @extend .arrow-footer-menu;
            }
            font-weight: 700;
            color: #fff;
            font-size: 12px;
            position: relative;
            padding: 0 0 15px 0;

        }

        .section-2{
            font-size: 12px;
            line-height: 18px;
            color: #767676;
        }

        .links h6{
            padding-bottom: 15px;
        }

        .social{
            font-size: 50px;
            a{
                text-decoration: none;
                color: #9d9d9d;
            }
            span:first-child{
                margin-left: -6px;
            }
        }

        hr {
            width: 100%;
            height: 1px;
            margin: 25px auto;
            background-color: rgba(157,157,157,0.25);
        }

        .subscribe-container{
            .field{
                position: relative;
                display: inline-block;
            }
            button {
                border: none;
                outline: none;
                background: none;
                font-size: 50px;
                position: absolute;
                right: -15px;
                top: -4px;
            }
            input{
                background-color: transparent;
                color: #9d9d9d;
                border: solid #9d9d9d 1px;
                padding: 10px;
                width: 160px;
                padding-right: 35px;
                font-size: 11px;
                @include placeholder {
                    text-transform: uppercase;
                    text-align: center;
                }
            }
        }
        
        .section-1 {
            &>div:first-child {
                padding-left: 0;
            }
        }

    }
}
