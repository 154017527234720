@import '../../partials/general/actions_dropdown';
@import '../../partials/general/actions_view';

.actions {
    padding: 25px 0;
    background-color: #fff;
    border-bottom: 1px solid #d9d9d9;
}

@media only screen and (max-width : 767px) {
  .actions {
      padding: 20px 0;
  }
}

