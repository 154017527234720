.showcase-container {

  .search-filters{
    border-bottom: 1px solid #d9d9d9;
  }

  .showcase {

    .see-more {
      a {
        color: #9d9d9d;
        font-size: 11px;
        text-decoration: none;
        text-transform: uppercase;
        span {
          position: relative;
          &:before {
            font-size: 50px;
            position: absolute;
            top: -19px;
            left: -15px;
          }
        }
        &:hover {
          text-decoration: none;
          color: #9d9d9d;
        }
      }
    }

    .left-details {
      .title {
        font-size: 25px;
        line-height: 29px;
        margin-bottom: 10px;
      }
      .picture {
        margin-bottom: 20px;
        height: 235px;
      }
    }

    .top-details {
      overflow: hidden;
      .picture {
        margin-bottom: 20px;
        width: 100%;
        height: 200px;
      }
    }

    .showcase-title {
      font-size: 25px;
      text-transform: uppercase;
      line-height: 29px;
    }
    .products-showcase{
      padding-top: 40px;
      padding-bottom: 60px;
      .slick-list {
        padding-top: 30px;
        margin-top: -30px;
      }
      .showcase-title {
        margin-bottom: 20px;
      }
      .product {
        .product-image {
          /*position: relative;*/
		  max-width: 160px;
		  position: relative;
		  margin: auto;
		  margin-bottom: 15px;
		  height: 250px;
          .image-wrapper {
			position: absolute;
			bottom: 0px;
            .image-position {
              overflow: hidden;
              position: relative;
            }
            img {
              width: 160px;
              //height: 235px;
			  max-height: 250px;
            }
          }
        }
        .discount-percentage {
          height: 45px;
          width: 45px;
          padding-top: 12px;
          border-radius: 25px;
          color: #ffffff;
          background-color: #FE0000;
          text-align: center;
          font-size: 14px;
          position: absolute;
          bottom: -15px;
          right: -15px;
        }
        .product-details {
          .extra-information {
            margin-bottom: 10px;
            .entry {
              //margin-bottom: 5px;
              color: #FE0000;
              text-transform: uppercase;
              &.last {
                margin-bottom: 0px;
              }
              &.new-tag {
                font-weight: bold;
                color: #ff0000;
              }
            }
          }
          .product-title {
            font-size: 18px;
            line-height: 22px;
            margin-bottom: 10px;
          }
          .product-author {
            margin-bottom: 10px;
          }
          .product-summary {
            margin-bottom: 10px;
          }
          .product-price {
            .with-discount {
              display: inline-block;
              margin-right: 10px;
              color: #FE0000;
              font-weight: bold;
              font-size: 18px;
              line-height: 22px;
            }
            .without-discount {
              display: inline-block;
              text-decoration: line-through;
              color: #9d9d9d;
            }
          }
          .product-rating {
            position: relative;
            left: -15px;
            .icon-e, .icon-E {
              font-size: 50px;
              color: #FE0000;
              display: inline-block;
              width: 25px;
            }
            .product-reviews-number {
              position: absolute;
              top: 15px;
              left: 145px;
            }
          }
        }
        .shopping-button {
          width: 160px;
          height: 40px;
          font-size: 11px;
          text-align: center;
          vertical-align: middle;
          text-transform: uppercase;
          padding: 0 40px;
          position: relative;
          margin-bottom: 5px;
          &.white {
            background-color: #ffffff;
            border: 1px solid #000000;
            color: #000000;
          }
          &.blue {
            background-color: #FE0000;
            border: none;
            color: #ffffff;
            font-weight: 600;
          }
          .icon-b {
            font-size: 50px;
            color: #ffffff;
            position: absolute;
            top: -5px;
            left: 0;
          }
        }
      }
      &.showcase-image-video {
        .see-more {
          margin-top: 20px;
        }
        .media-container {
          margin-bottom: 20px;
        }
        .video, .picture {
          width: 100%;
          height: 100%;
        }
      }
      &.showcase-6x {
        padding-top: 25px;
        padding-bottom: 50px;
        .products-list {
          margin-top: 30px;
          .slick-arrow {
            top: 120px;
          }
          .product {
            .product-image {
			  /*height: 235px;*/
			  height: 250px;
              .image-wrapper {
                position: absolute;
                bottom: 0px;
              }
            }
            .product-details {
              .extra-information {
                margin-top: 15px;
              }
              .product-title {
                font-size: 18px;
                line-height: 22px;
                margin-bottom: 5px;
              }
              .product-rating {
                top: -5px
              }
            }
          }
        }
      }
      &.showcase-1-1, &.showcase-2x {
        .product {
			.product-image {
				height: 250px;
				.image-wrapper {
					margin-bottom: 5px;
					position: relative;
					top: 0;
					bottom: auto;
					/*
					margin-bottom: 15px;
					position: absolute;
					top: 0px;
					*/
					.discount-percentage {
						top: -15px;
						bottom: auto;
					}
				}
			}
          .product-details {
            .product-title {
              font-size: 25px;
              line-height: 29px;
            }
            .product-price {
              .with-discount {
                font-size: 25px;
                line-height: 29px;
              }
            }
            .product-author {
              margin-bottom: 20px;
            }
            .product-summary {
              margin-bottom: 15px;
            }
          }
        }
      }
      &.showcase-4x {
        .products-list {
          .slick-arrow {
            top: 112px;
          }
        }
        .product {
			.product-image {
				height: 250px;
				margin: 0;
				.image-wrapper {
					position: absolute;
					top: 0px;
					img {
						//height: 235px;
					}
					.discount-percentage {
						top: -15px;
						bottom: auto;
					}
				}
			}
			.product-details {
				.extra-information {
				  margin-top: 20px;
				}
			}
        }
      }
      &.showcase-3x {
		.product {
			.product-image {
				height: 250px;
				margin-bottom: 5px;
				/*margin-bottom: 15px;*/
				.image-wrapper {
					top: 0px;
					.discount-percentage {
						top: -15px;
						bottom: auto;
					}
				}
			}
		}
      }
      &.showcase-image-3 {
        padding-top: 40px;
        padding-bottom: 45px;
        .showcase-title {
          margin-bottom: 25px;
        }
        .product {
          .product-image {
            /*margin-bottom: 5px;*/
			height: 250px;
			margin-bottom: 15px;
          }
        }
        .media-container {
          height: 200px;
        }
      }
      &.showcase-image-2 {
        .top-details {
          .picture {
            margin-bottom: 25px;
          }
        }
        .media-container {
          height: 305px;
        }
      }
      &.showcase-image-1 {
        padding-bottom: 40px;
        padding-top: 35px;
        .product {
          .product-details {
            .extra-information {
              margin-top: 15px;
            }
          }
        }
        .video, .picture {
          height: 305px;
        }
      }
      &.showcase-video-1 {
        .product {
          .product-details {
            .extra-information {
              margin-top: 15px;
            }
          }
        }
        .media-container {
          height: 305px;
        }
      }
      &.showcase-video-2 {
        .media-container {
          height: 305px;
        }
      }
      &.showcase-video-3 {
        .media-container {
          height: 200px;
        }
      }
    }
    &.even {
      background-color: #f7f7f7;
      .products-showcase{
        .product {
          .product-details {
            .product-price {
              .without-discount {
                color: #9d9d9d;
              }
            }
          }
        }
      }
    }
    &.odd {
      background-color: #d9d9d9;
      .products-showcase{
        .product {
          .product-details {
            .product-price {
              .without-discount {
                color: #fff;
              }
            }
          }
        }
      }

    }
    &.normal {
      background-color: #fff;
      .products-showcase,
      .featured-products-slider {
        /*border-bottom: 1px solid #000;*/
      }
      &.last {
        .products-showcase,
        .featured-products-slider {
          border-bottom: none;
        }
      }
    }
  }
}

.featured-products-slider {
  .featured-products-wrapper {
    position: relative;
    overflow: visible;
    .slider-banner-bg-img {
      /*background-color: $showcase-top-slider-bg-color;*/
      position: absolute;
      overflow: hidden;
      width: 100%;
      height: 100%;
      > img {
        position: absolute;
        min-width: 100%;
        min-height: 100%;
      }
    }
    .featured-products {
      position: relative;
      .slick-list {
        height: 100%;
      }
      .featured-products-slider-arrow{
        position: absolute;
        top: 50%;
        left: 50%;
      }
      .featured-product {
        position: relative;
        overflow: hidden;
        &:not(.slider-full-width):not(.slick-slide) {
          position: absolute;
          left: 100%;
        }
        &.slider-full-width{
          overflow: hidden;
          position: relative;
        }
        .slider-banner{
          min-width: 100%;
          height: auto;
          min-height: 350px;
          //background-size: auto 100%;
          background-size: cover;
          background-position: center;
          .details {
            padding: 0;
            position: inherit;
            .bg-img {
              position: absolute;
              min-width: 100%;
              min-height: 100%;
              top: 0;
              left: 0;
              z-index: -1;
              width: auto;
              height: auto;
            }
          }
        }
        .picture {
          box-shadow: none;
          max-width: 380px;
          width: calc(100% - 30px);
          max-height: none;
          margin-top: auto;
          margin-right: auto;
          float: none;

          -ms-transform: rotate(-10deg); /* IE 9 */
          -ms-transform-origin: 0 0; /* IE 9 */
          -webkit-transform: rotate(-10deg); /* Chrome, Safari, Opera */
          -webkit-transform-origin: 0 0; /* Chrome, Safari, Opera */
          transform: rotate(-10deg);
          transform-origin: 0 0;
        }
        .details {
          position: inherit;
          margin: 0 auto;
          min-height: 300px;
          display: table;
          .details-container {
            margin: auto;
            vertical-align: middle;
            display: table-cell;
            padding-right: 30px;
          }
          .bg-img {
            position: absolute;
            min-width: 100%;
            min-height: 100%;
            top: 0;
            left: 0;
            z-index: -1;
          }
          .title {
            font-size: 54px;
            line-height: 56px;
            padding-bottom: 15px;
            margin-bottom: 0;
            font-weight: 600;
            overflow: hidden;
          }
          .subtitle {
            font-size: 32px;
            line-height: 34px;
            padding-bottom: 20px;
            margin-bottom: 0;
            font-weight: 300;
            overflow: hidden;
          }
          .description {
            font-size: 14px;
            line-height: 18px;
            padding: 0;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 4;
            max-height: 170px;
          }
        }
      }
    }
  }
  .top-details {
    padding: 0px 15px;
  }
  .slick-slider {
    .slick-dots {
      bottom: 10px;
    }
  }
}
